var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      ref: "dialog",
      attrs: { title: _vm.title, color: "error" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "v-btn",
                {
                  staticClass: "back",
                  attrs: { width: "25%", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("button.close")) + "\n    ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("v-container", [
        _c(
          "div",
          { staticClass: "text-center", staticStyle: { margin: "0 auto" } },
          [_c("p", [_vm._v(_vm._s(_vm.message))])]
        )
      ]),
      _vm.errorMessageList.length > 0
        ? _c(
            "v-container",
            {
              staticClass: "overflow-y-auto mx-auto",
              staticStyle: {
                "max-height": "30vh",
                width: "90vw",
                border: "#ddd 3px solid"
              },
              attrs: { id: "scroll-target", wrap: "" }
            },
            _vm._l(_vm.errorMessageList, function(text, index) {
              return _c("v-row", { key: index, staticClass: "my-0" }, [
                _vm._v("\n      " + _vm._s(text) + "\n    ")
              ])
            }),
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }